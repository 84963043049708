@font-face {
    font-family: 'IndieFlower';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('IndieFlower'), local('IndieFlower-Regular'), url(./fonts/IndieFlower-Regular.ttf) format('ttf');
  }

html {
  scrollbar-gutter: stable;
}